import './config.js';
import './error-handling.js';
import { get_session, get_username, show_signup, show_login } from './auth.js';

import './classicswapmeet.scss';
import './fonts.css';

/* Common functions */

export function splash(content, hide_close){
  document.querySelector(".splash_content_variable").textContent = '';
  if (hide_close){
    document.querySelector(".splash_content_header").style.display = 'none';
  } else {
    document.querySelector(".splash_content_header").style.display = '';
  }
  document.querySelector(".splash_content_variable").insertAdjacentHTML('beforeend', content);
  document.querySelector(".splash").style.display = 'table';
  document.querySelector("body").style.overflow = 'hidden';
}

export function splash_close(message){
  document.querySelector(".splash_content_variable").textContent = '';
  if (message){
    let html = `<div class=splash_close_message>${message}</div>`;
    document.querySelector(".splash_content_variable").insertAdjacentHTML('beforeend', html);
  } else {
    document.querySelector('.splash').style.display = 'none';
    document.querySelector('body').style.overflow = 'auto';
  }
}

export function increment_metric(metric, dimensions){
  let data;
  if (typeof dimensions === 'undefined'){
    data = {'dimensions': []};
  } else {
    data = {'dimensions': dimensions};
  }

  fetch(window._config.apiUrl + '/metrics/' + metric, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (!response.ok){
      console.error(`url: ${response.url}`);
      console.error(`status: ${response.status}`);
      console.error(`statusText: ${response.statusText}`);
      console.error(`headers: ${JSON.stringify(response.headers)}`);
    }
  });
}

/* Site specific functions */

const post_new_item = (item, callable) => {
  let files = [];
  for (let i = 0; i < item?.files?.length; i++){
    files.push(item.files[i]);
  }

  let data = item;
  delete data['files'];

  get_session(() => {
    fetch(window._config.apiUrl + '/items/', {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'authorization': window._token,
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((r) => {
      return r.json()
    }).then((item) => {
      files.forEach((file) => {
        get_image_upload_parameters(item, (parameters) => {
          upload_image(file, parameters['post_parameters'], () => console.log('done'));
        });
      });
    }).then(() => {
      show_public_items();
      show_my_items();
      show_my_deleted_items();
    });
  });
};

const get_image_upload_parameters = (item, callable) => {
  let data = item;

  get_session(() => {
    fetch(window._config.apiUrl + '/items/' + item['id'] + '/images/', {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'authorization': window._token,
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((r) => r.json().then((json) => callable(json)));
  });
};

const upload_image = (image, post_parameters, callable) => {
  let form_data = new FormData();

  for (let key in post_parameters['fields']){
      form_data.append(key, post_parameters['fields'][key]);
  }
  form_data.append('file', image);

  fetch(post_parameters['url'], {
    method: 'POST',
    mode: 'cors',
    body: form_data
  }).then((r) => callable(r));
};

//success: function(response_data){
//    if (response_data['post_parameters']){
//        var form_data = new FormData();
//        for (let key in response_data['post_parameters']['fields']){
//            form_data.append(key, response_data['post_parameters']['fields'][key]);
//        }
//        form_data.append('file', image);
//
//        $progress_bar.parent().attr('id', response_data['id'] + '_progress');
//
//        $.ajax({
//            url: response_data['post_parameters']['url'],
//            data: form_data,
//            type: 'POST',
//            contentType: false,
//            processData: false,
//            xhr: function(){
//                var xhr = new window.XMLHttpRequest();
//                xhr.upload.addEventListener("progress", function(e){
//                    if (e.lengthComputable){
//                        $progress_bar.attr({'value': e.loaded, 'max': e.total});
//                    }
//                }, false);
//
//                return xhr;
//            },
//            error: function(x, s, e){
//                console.error(this.url + ' xhr: ' + JSON.stringify(x));
//                console.error(this.url + ' status: ' + s);
//                console.error(this.url + ' error: ' + e);
//            },
//            success: function(){
//                add_item_to_category(category_id, response_data['id'], response_data['image_id'], '', caption, description, true, undefined, type);
//            }
//        })
//    } else {
//        add_item_to_category(category_id, response_data['id'], undefined, '', caption, description, true, undefined, type);
//    }
//}

const remove_image_from_item = (item, image, callable) => {
  get_session(() => {
    fetch(window._config.apiUrl + '/items/' + item['id'] + '/images/' + image, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'authorization': window._token,
      }
    }).then((response) => {
      if (callable){
        callable(response);
      }
    })
  });
};

const purge_deleted_item = (item, callable) => {
  get_session(() => {
    fetch(window._config.apiUrl + '/items/' + item['id'], {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'authorization': window._token,
      }
    }).then((response) => {
      if (callable){
        callable(response);
      }
    })
  });
};

const update_item_status = (item, callable) => {
  let data = {'status': item['status']};

  get_session(() => {
    fetch(window._config.apiUrl + '/items/' + item['id'], {
      method: 'PUT',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'authorization': window._token,
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (callable){
        callable(response);
      }
    })
  });
};

const update_item_primary_image = (item, callable) => {
  let data = {'primary_image': item['primary_image']};

  get_session(() => {
    fetch(window._config.apiUrl + '/items/' + item['id'], {
      method: 'PUT',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'authorization': window._token,
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (callable){
        callable(response);
      }
    })
  });
};

const list_items = (data, callable) => {
  get_session(() => {
    fetch(window._config.apiUrl + '/items/?' + new URLSearchParams(data), {
      method: 'GET',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'authorization': window._token,
        'content-type': 'application/json'
      },
    }).then((r) => r.json().then((json) => callable(json)));
  });
};

function show_public_items(){
  list_items({}, (results) => {
    if (results?.items?.length > 0){
      let html = `Public Items <table border=1 width=100%>`;
      results?.items?.forEach((item) => {
        html += `<tr><td>${item?.id}</td>
            <td>${item?.username}</td>
            <td>${item?.status}</td>
            <td>${item?.title}</td>
            <td>${item?.description}</td></td>`;
      });
      html += `</table>`;
      document.querySelector(".public-items").innerHTML = html;
    } else {
      document.querySelector(".public-items").innerHTML = '';
    }
  });
};

function show_my_items(){
  list_items({'username': get_username()}, (results) => {
    let items = results?.items.filter((e) => {
      if (e['status'] != 'deleted'){
        return e;
      }
    });
    if (items?.length > 0){
      let html = `My items <table border=1 width=100%>`;
      items.forEach((item) => {
        html += `<tr><td>${item?.id}</td>
            <td>${item?.username}</td>
            <td>${item?.status}</td>
            <td>${item?.title}</td>
            <td>${item?.description}</td>
            <td>Edit, `;

        if (item['status'] == 'active'){
          html += `<a href="" class=update-item-status status=inactive id_=${item.id}>Make Draft</a>, `;
        } else {
          html += `<a href="" class=update-item-status status=active id_=${item.id}>Make Active</a>, `;
        }

        if (item['status'] != 'deleted'){
          html += `<a href="" class=update-item-status status=deleted id_=${item.id}>Delete</a> `;
          html += `<form>Add Images <input type=file id=images-${item.id} multiple />
              <a href="" class=add-images-to-item-submit id_=${item.id}>Submit</a></form></td></tr>`;
        } else {
          html += `<a href="" class=purge-item id_=${item.id}>Purge</a></td></tr>`;
        }
      });
      html += `</table>`;
      document.querySelector(".my-items").innerHTML = html;
    } else {
      document.querySelector(".my-items").innerHTML = '';
    }
  });
};

function show_my_deleted_items(){
  list_items({'username': get_username(), 'status': 'deleted'}, (results) => {
    if (results?.items?.length > 0){
      let html = `My deleted items <table border=1 width=100%>`;
      results?.items?.forEach((item) => {
        html += `<tr><td>${item?.id}</td>
            <td>${item?.username}</td>
            <td>${item?.status}</td>
            <td>${item?.title}</td>
            <td>${item?.description}</td>
            <td>`;

        html += `<a href="" class=update-item-status status=inactive id_=${item.id}>Restore</a>, `;
        html += `<a href="" class=purge-item id_=${item.id}>Purge</a></td></tr>`;
      });
      html += `</table>`;
      document.querySelector(".my-deleted-items").innerHTML = html;
    } else {
      document.querySelector(".my-deleted-items").innerHTML = '';
    }
  });
};

function show_add_item_form(){
  let html = `<form>
      Title <input type=text id=title />
      Description <input type=text id=description />
      Images <input type=file id=images multiple />
      <a href="" class=add-item-submit>Submit</a></form>`;
  document.querySelector(".add-item").innerHTML = html;
};

document.addEventListener('click', (event) => {
  if (event.target.matches('a.add-item-submit')){
    event.preventDefault();

    let data = {'title': document.querySelector("#title")?.value.trim(),
        'description': document.querySelector("#description")?.value.trim(),
        'status': 'active',
        'files': document.getElementById("images").files};

    post_new_item(data, () => {
      show_public_items();
      if (get_username()){
        show_my_items();
        show_my_deleted_items();
      }
    });
    show_add_item_form();

  } else if (event.target.matches('a.purge-item')){
    event.preventDefault();
    let id = event.target.getAttribute('id_');

    purge_deleted_item({'id': id}, () => {
      show_public_items();
      show_my_items();
      show_my_deleted_items();
    });

  } else if (event.target.matches('a.update-item-status')){
    event.preventDefault();
    let id = event.target.getAttribute('id_');
    let status = event.target.getAttribute('status');

    update_item_status({'id': id, 'status': status}, () => {
      show_public_items();
      show_my_items();
      show_my_deleted_items();
    });

  } else if (event.target.matches('a.add-images-to-item-submit')){
    event.preventDefault();

    let item_id = event.target.getAttribute('id_');
    let files_element = document.getElementById(`images-${item_id}`).files;
    let files = [];
    for (let i = 0; i < files_element.length; i++){
      files.push(files_element[i]);
    }

    files.forEach((file) => {
      get_image_upload_parameters({'id': item_id}, (parameters) => {
        upload_image(file, parameters['post_parameters'], () => console.log('done'));
      });
    });

  } else if (event.target.matches('button.splash_close')){
    splash_close();
  }
});

document.addEventListener("DOMContentLoaded", () => {
  //
  // Used for all HTML pages
  //
  let username = get_username();
  let html;
  if (username){
    html = `<div class=userinfo><a href="" class=logout>${username}</a></div>`;
  } else {
    html = `<div class=userinfo id=loginbutton><a href="" class=show_signup>Sign up</a>
        <a href="" class=show_login>Login</a></div>`;
  }
  document.querySelector("#loginStatus").insertAdjacentHTML('beforeend', html);

  // TODO: metrics

  show_public_items();
  if (get_username()){
    show_my_items();
    show_add_item_form();
    show_my_deleted_items();
  }
});
